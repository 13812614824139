import React from 'react';
import Page from '../../components/page';

export default () => (
  <Page
    id="not-found"
    title="Not Found"
    description="This is embarrassing."
    noCrawl
  >
    <p>Super embarrassing.</p>
  </Page>
);
